/* TODO: move to App.js theme */
body {
  margin: 0;
  font-family: "Source Sans Pro", "Roboto", "Arial", "Helvetica", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #fafafa;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html,
body,
#main {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: 400;
}

h1 > small {
  display: block;
  margin-top: 5px;
  color: rgba(0, 0, 0, 0.8);
}

.appContainer {
  width: 100%;
  height: 100%;
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: space-between;
}

.contentContainer {
  margin: auto 10%;
  flex: 1;
}

.editorContainer {
  width: 100%;
  height: calc(100% - 64px);
  flex: 1;
}

.tly-MuiPopover-root,
.tly-MuiSnackbar-root,
.tly-MuiTooltip-popper,
.MuiPopover-root,
.MuiSnackbar-root,
.MuiTooltip-popper {
  z-index: 999999 !important;
}
