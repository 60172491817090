/* TODO: get rid of this css file */

#print-favorites * {
  visibility: hidden;
  display: none;
}

@media print {
  html, body, #main {
    width: auto;
    height: auto;
  }

  body * {
    visibility: hidden;
  }

  #print-favorites * {
    visibility: visible;
    display: block;
  }

  #print-favorites {
    position: fixed;
    left: 0;
    top: 0;
    width: 800px;
  }
}
