.fading-500-enter, .fading-500-appear {
  opacity: 0;
}
.fading-500-enter-active, .fading-500-appear-active {
  opacity: 1;
  transition: opacity 500ms ease-in !important;
}
.fading-500-exit {
  opacity: 1;
}
.fading-500-exit-active {
  opacity: 0;
  transition: opacity 500ms ease-in !important;
}
.fading-500-exit-active * {
  pointer-events: none !important;
}

/* move to side animation
.fading-1000-enter, .fading-1000-appear {
  opacity: 0;
  transform: translateX(2000px);
}
.fading-1000-enter-active, .fading-1000-appear-active {
  opacity: 1;
  transform: translateX(0);
  transition: all 1000ms ease-in-out !important;
}
.fading-1000-exit {
  opacity: 1;
  transform: translateX(0);
}
.fading-1000-exit-active {
  opacity: 0;
  transform: translateX(-2000px);
  transition: all 1000ms ease-in-out !important;
}
.fading-1000-exit-active * {
  pointer-events: none !important;
}
*/